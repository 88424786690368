(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * Simply create an element in your template with the class `.btn-facebook` and FacebookConnect does the rest! (TM)
   * fine print: header requirements, example found in frontend/views/layout.handlebars
   * @mixin FacebookConnect
   */

  var PERMISSIONS = "email,user_birthday";

  app.add(
    "FacebookConnect",
    Monaco.Mixin.create({
      events: {
        "tap .btn-facebook": "onFacebookConnect",
        "click .btn-facebook": "stopEvent",

        "tap .btn-facebook-link": "onFacebookLink",
        "click .btn-facebook-link": "stopEvent"
      },

      initialize: function(options, next) {
        options = options || {};
        this.nextUrlFirstPart = options.nextUrlFirstPart;

        _.bindAll(
          this,
          "onFacebookConnect",
          "onFacebookLink",
          "getPermissions",
          "getWattpadStateToken",
          "facebookSend"
        );

        // Add the Facebook Javascript SDK to the page if it isn't already there
        if ($("#fb-root").length === 0) {
          var fbSdk = window.document.createElement("script");
          fbSdk.async = 1;
          fbSdk.src = "https://connect.facebook.net/en_US/sdk.js";
          fbSdk.id = "facebook-jssdk";

          $("body")
            .append("<div id='fb-root'></div>")
            .append(fbSdk);
        }
        next(options);
      },

      tries: 0,

      // sign in or sign up with facebook account
      onFacebookConnect: function(evt, next) {
        var url = this.nextUrlFirstPart || window.location.href;
        var nextUrl = wattpad.utils.getNextUrl(url);

        this.getPermissions(function() {
          window.location.href =
            "/fb-auth?nextUrl=" +
            nextUrl +
            "&access_token=" +
            window.FBStatus.accessToken +
            "&redirect=true";
        });

        next();
      },

      // link facebook account with current user
      onFacebookLink: function(evt, next) {
        var url = this.nextUrlFirstPart || window.location.href;
        var nextUrl = wattpad.utils.getNextUrl(url);

        this.getWattpadStateToken().then(function(state_token) {
          const username = wattpad.utils.currentUser().get("username");
          const redirectUri = encodeURIComponent(
            `${
              wattpad.siteRoot
            }/fb-auth?nextUrl=${nextUrl}&username=${username}`
          );
          window.location.replace(
            `https://www.facebook.com/v9.0/dialog/oauth?` +
              `client_id=${window.wp.app_id}` +
              `&redirect_uri=${redirectUri}` +
              `&state=${state_token}` +
              `&response_type=code`
          );
        });

        next();
      },

      getPermissions: function(connect, next) {
        var self = this;

        var fbMethod = utils.currentUser().get("facebook")
          ? window.wp.facebook_reprompt_permission
          : window.wp.facebook_prompt_permission;

        if (fbMethod) {
          fbMethod(PERMISSIONS, function() {
            if (
              !self.facebookConnected ||
              !utils.currentUser().get("facebook")
            ) {
              if (typeof connect === "function") {
                connect();
              }
            } else {
              self.facebookConnected();
            }
          });
        } else if (this.tries > 4) {
          window.alert(
            wattpad.utils.trans(
              "Error logging in through Facebook. Please refresh and try again."
            )
          );
        } else {
          this.tries++;
          window.setTimeout(self.getPermissions, 500);
        }

        next();
      },

      getWattpadStateToken: function() {
        return Promise.resolve(
          $.ajax({
            type: "GET",
            url:
              "/v4/users/" +
              utils.currentUser().get("username") +
              "/facebook_hmac"
          })
        )
          .then(function(result) {
            return result.token;
          })
          .catch(function(error) {
            $(".errorMessage").html(
              wattpad.utils.trans(error.responseJSON.message)
            );
            $(".alert-danger").removeClass("hidden");
          });
      },

      facebookSend: function(link, next) {
        FB.ui({
          method: "send",
          link: link
        });
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
