import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../shared-components";
import { useTrans, useFeatureFlags } from "../../hooks";
import ThirdPartyAuth from "./ThirdPartyAuth";
import classNames from "classnames";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";

const ThirdPartyLogin = ({
  focus,
  msg,
  nextUrl = "/home",
  notice,
  toggleFormView
}) => {
  const { trans } = useTrans();
  const featureFlags = useFeatureFlags();
  const hrText = featureFlags.NEW_ONBOARDING_1 ? "or" : "OR";
  const hrWithTextNew = featureFlags.NEW_ONBOARDING_1
    ? "hr-margin-new"
    : "hr-margin";
  const loginText = featureFlags.NEW_ONBOARDING_1
    ? trans("Log in with email")
    : trans("Log in");

  return (
    <>
      <ThirdPartyAuth source="login" nextUrl={nextUrl} focus={focus} />
      <div className={classNames("hr-with-text", hrWithTextNew)}>
        <div className="horizontal-line" />
        <span className="or"> {hrText} </span>
        <div className="horizontal-line" />
      </div>
      <button
        className={classNames("btn-block btn-primary", {
          "submit-btn-new": featureFlags.NEW_ONBOARDING_1
        })}
        onClick={toggleFormView}
      >
        {loginText}
      </button>
      {featureFlags.NEW_ONBOARDING_1 ? (
        <Button
          variant={ButtonVariant.TERTIARY}
          href={`/forgot?nextUrl=${nextUrl}`}
          label={trans("Forgot password?")}
          fullWidth
        />
      ) : (
        <div className="password-forgot">
          <a href={`/forgot?nextUrl=${nextUrl}`}>{trans("Forgot password?")}</a>
        </div>
      )}
      {msg && (
        <div className="alert alert-danger" role="alert">
          <Icon iconName="fa-info" height="16" color="wp-lighterror" />
          {msg}
        </div>
      )}
      {notice && (
        <div className="alert alert-info" role="alert">
          <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
          {notice}
        </div>
      )}
    </>
  );
};

ThirdPartyLogin.propTypes = {
  focus: PropTypes.bool,
  msg: PropTypes.string,
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  toggleFormView: PropTypes.func
};

export default ThirdPartyLogin;
