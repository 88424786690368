const {
  getSearchEligibility
} = require("../../../../../helpers/ads/get-ad-eligibility");

(function(window, wattpad, app, utils) {
  "use strict";

  app.router.on(
    "route:search",
    app.router.filter(["getTranslations", "getCategories"], function(
      query,
      resultType
    ) {
      query = query || "";

      const browseTopicsCollection = new app.collections.BrowseTopics();
      let adEligibility = [];

      function navigateToView() {
        let options = {
          query: query ? query.trim() : "",
          limits: { users: 20, stories: 15 },
          browseTopics: browseTopicsCollection,
          adEligibility
        };

        options.resultType = resultType || "stories";

        if (typeof __atha !== "undefined") {
          var id = __atha.sendPageView(`search_${options.resultType}`);
        }

        if (wattpad?.testGroups.AD_ELIGIBILITY) {
          getSearchEligibility().then(response => {
            options.adEligibility = response;
          });
        }

        const view = new app.views.SearchLanding(options);

        const title = utils.getSearchTitle(query);

        app.transitionTo(view, {
          hasHeader: true,
          hasFooter: true,
          pageTitle: title
        });

        // If the search term is empty, we have to assume they're on the search landing page
        if (!query.length) {
          window.te.push("event", "app", "page", null, "view", {
            page: "search"
          });
        } else {
          window.te.push("event", "app", "page", null, "view", {
            page: "search_results"
          });
        }
      }

      // Fetch browse topics for new home experience
      Promise.resolve(browseTopicsCollection.fetch())
        .then(function() {
          if (typeof __atha !== "undefined") {
            var id = __atha.sendPageView(`search_${resultType || "stories"}`);
          }

          return new Promise(resolve => {
            if (!wattpad?.testGroups.AD_ELIGIBILITY) return resolve();

            getSearchEligibility({}).then(response => {
              adEligibility = response;
              return resolve();
            });
          });
        })
        .then(function() {
          navigateToView();
        });
    })
  );
})(window, wattpad, window.app, wattpad.utils);
