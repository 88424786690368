import partTextContainer from "core/templates/story-reading/part-text-container.hbs";
import pictureViewer from "core/templates/story-reading/picture-viewer.hbs";
import partSimilar from "core/templates/story-reading/part-similar.hbs";
import partRestart from "core/templates/story-reading/part-restart.hbs";
import partRecommendations from "core/templates/story-reading/part-recommendations.hbs";
import partNavigation from "core/templates/story-reading/part-navigation.hbs";
import partHeaderNew from "core/templates/story-reading/part-header-new.hbs";
import partFooterNew from "core/templates/story-reading/part-footer-new.hbs";
import partContainerNew from "core/templates/story-reading/part-container-new.hbs";
import partActions from "core/templates/story-reading/part-actions.hbs";
import commentMarker from "core/templates/story-reading/comment-marker.hbs";
import bannedImageMessaging from "core/templates/banned-image-messaging.hbs";
import partTopAd from "core/templates/story-reading/part-top-ad.hbs";
import getAdStrings from "../../../../helpers/ads/get-ad-strings";
import setPropsForReactInHb from "../../../../helpers/handlebars/set-props-for-react-in-hb";

import {
  CommentPlacement,
  createUniqueAdId,
  getAd,
  includeAdMarkup,
  ReaderPlacement
} from "@wattpad/client-platform-web-ads";
import { MODAL_ID } from "../../../../components/shared-components";
import sendAdReconciliationEvent from "../../../../helpers/ads/send-ad-reconciliation-event";
import { readerAndCommentPlacements } from "../../../../helpers/ads/get-ad-placements";

const MID_LONG_AD_REFRESH_TIME = 5000;

(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "CoreStoryReading",
    app.views.RegionManager.extend({
      template: function() {
        return "";
      },

      partials: {
        "core.story_reading.comment-marker": commentMarker,
        "core.story_reading.funbar": function() {
          return "";
        },
        "core.story_reading.media_banner": function() {
          return "";
        },
        "core.story_reading.part_actions": partActions,
        "core.story_reading.part_container_new": partContainerNew,
        "core.story_reading.part_footer_new": partFooterNew,
        "core.story_reading.part_header_new": partHeaderNew,
        "core.story_reading.part_navigation": partNavigation,
        "core.story_reading.part_recommendations": partRecommendations,
        "core.story_reading.part_restart": partRestart,
        "core.story_reading.part_similar": partSimilar,
        "core.story_reading.picture_viewer": pictureViewer,
        "core.story_reading.part_text_container": partTextContainer,
        "core.banned_image_messaging": bannedImageMessaging,
        "core.story_reading.part_top_ad": partTopAd
      },

      regions: {},

      commentTimer: 0,
      commentAdId: null,

      bannerSignup: true,

      isDesktop: null,
      id: "story-reading",
      voteButton: ".actions > .btn-vote",

      subviews: {
        funbar: app.views.StoryReadingFunbar,
        media: app.views.MediaBanner,
        comments: app.views.Comments,
        bottomBanner: app.views.BottomBanner
      },

      regionTags: {
        funbar: "#funbar-container",
        media: "#media-container",
        comments: "#comments",
        bottomBanner: "#bottom-banner-container"
      },

      events: {
        "tap   .share-embed": "onShareEmbed",
        "click .share-embed": "stopEvent",

        "tap    .on-dismiss-lists": "onDismissLists",
        "click  .on-dismiss-lists": "stopEvent",

        "tap   .on-send": "onSendToFriend",
        "click .on-send": "stopEvent",

        "keyup .send-input": "onValidateSend",

        "tap    .on-load-more-page": "onLoadNextPage",
        "click  .on-load-more-page": "stopEvent",

        "tap .share-post-to-profile": "showPostToProfileModal",
        "click .share-post-to-profile": "stopEvent"
      },

      initialize: function(options) {
        var self = this;

        this.adEligibility = options.adEligibility;

        _.each(this.partials, function(value, key) {
          Handlebars.registerPartial(key, value);
        });

        _.bindAll(
          this,
          "buyPaidContent",
          "showStoryPaywallModal",
          "onBuyCoins"
        );

        this.scrollState = { part: {} };

        this.refreshDelay = 30; // number of seconds between refresh
        this.refreshRemaining = 30; // remaining number of refresh that should happen

        // Need this object to be unique per object, so instantiate it here
        this.sync = {
          enabled: true,
          last: 0,
          part: 0
        };

        if (wattpad.utils.currentUser().authenticated()) {
          this.listenTo(this.model, "reading-position-available", function(
            percent
          ) {
            self.sync = {
              enabled: true,
              last: percent
            };
          });
          this.listenTo(app, "app:story:vote", this.onUpdateVote);
          this.model.syncPosition();
        } else {
          this.sync.enabled = false;
        }

        if (options.deepLinkModel) {
          const deepLinkModel = options.deepLinkModel;
          const paragraphId = deepLinkModel.get("paragraphId");

          this.model.set("deeplinkData", {
            commentId: deepLinkModel.isDeeplinkedReply
              ? deepLinkModel.commentIdToHighlight
              : deepLinkModel.id,
            paragraphId: paragraphId
          });
        }

        if (self.model.get("pages") >= 2 && app.get("device").is.desktop) {
          const adUnit = getAdStrings(
            "mid",
            self.model.attributes,
            wattpad.user !== null,
            app.get("device").is.mobile
          ).adUnit;

          utils.getCookie("debug-ads") &&
            console.debug("Story is 2+ pages, setting up mid long ad");
          this.uniqueMidLongAdId = createUniqueAdId(adUnit);
        }
      },

      showPostToProfileModal: function(e) {
        var self = this,
          $genericModal = $("#generic-modal");

        utils.stopEvent(e);

        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(e);
          return;
        } else if (!wattpad.utils.currentUser().get("verified_email")) {
          utils.showPleaseVerifyModal();
          return;
        } else {
          var postToProfileModal = new app.views.PostToProfileModal({
            model: this.model,
            target: utils.currentUser().get("username")
          });
          $genericModal.empty().append(postToProfileModal.render().$el);
          $genericModal.modal("show");
        }
      },

      getParagraphBody: function(paragraphId) {
        let paragraphBody;
        _.each(this.model.loadedPages, loadedPage => {
          paragraphBody = loadedPage.getParagraphById(paragraphId);
          if (paragraphBody) {
            paragraphBody = Handlebars.helpers.parseEmbeddedMedia(
              paragraphBody,
              460
            );
            return false;
          }
        });
        return paragraphBody;
      },

      render: function() {
        var self = this;

        this.model.set("isDeepLinkView", !!this.options.deepLinkModel);

        if (!wattpad.utils.isOperaMini) {
          $(window).on("scroll.screen_" + this.cid, function(e) {
            self.delegateScrollEvent(e);
          });
        }
        // Inline ad unit
        var { adUnit } = getAdStrings(
          "inline_comments",
          self.model.attributes,
          wattpad.user !== null,
          app.get("device").is.mobile
        );

        const adData = {
          userGeo: wattpad.userCountryCode,
          testGroups: wattpad.testGroups,
          deviceType: wattpad.utils.getDeviceType(),
          context: {
            user: wattpad.utils.currentUser().toJSON(),
            story: {
              id: parseInt(self.model.get("group")?.id),
              user: self.model.get("group")?.user,
              isAdExempt: self.model.get("group")?.isAdExempt,
              isBrandSafe: self.model.get("group")?.isBrandSafe,
              isPaywalled: self.model.get("group")?.isPaywalled,
              rating: self.model.get("group")?.rating,
              language: self.model.get("group")?.language.id,
              imagesUnderModeration: self.model.get("group")
                ?.imagesUnderModeration,
              category: self.model.get("group")?.category,
              categories: self.model.get("group")?.categories
            },
            storyPart: {
              id: parseInt(self.model.get("id")),
              brandSafetyLevel: self.model.get("brandSafetyLevel"),
              brandSafetySource: self.model.get("brandSafetySource"),
              rank: self.model.get("rank"),
              rating: self.model.get("rating"),
              totalViewCount: self.model.get("readCount"),
              imagesUnderModeration: self.model.get("imagesUnderModeration")
            }
          },
          placement: adUnit
        };
        self.model.set("adData", adData);

        if (wattpad.testGroups.USE_IMAGE_MOD) {
          adData.context.storyPart.imagesUnderModeration = self.model.get(
            "imagesUnderModeration"
          );
        }

        const placement = getAdStrings(
          "comments",
          self.model.attributes,
          wattpad.user !== null,
          app.get("device").is.mobile
        ).adUnit;

        const commentsAdProps = {
          placement,
          adData: adData,
          className: "comments-ad " + placement
        };
        setPropsForReactInHb(self.model, "commentsAdProps", commentsAdProps);

        const readingBottomProps = {
          placement: getAdStrings(
            "bottom",
            self.model.attributes,
            wattpad.user !== null,
            app.get("device").is.mobile
          ).adUnit,
          adData: adData,
          className: "reading-bottom-ad"
        };
        setPropsForReactInHb(
          self.model,
          "readingBottomProps",
          readingBottomProps
        );

        const readingTopProps = {
          placement: getAdStrings(
            "top",
            self.model.attributes,
            wattpad.user !== null,
            app.get("device").is.mobile
          ).adUnit,
          adData: adData,
          className: "reading-top-ad"
        };
        setPropsForReactInHb(self.model, "readingTopProps", readingTopProps);
        if (self.model.get("pageNumber") <= 2) {
          this.renderTopAdUnit();
        }

        if (wattpad?.testGroups?.AD_ELIGIBILITY) {
          const midAdUnit = getAdStrings(
            "mid",
            self.model.attributes,
            wattpad.user !== null,
            app.get("device").is.mobile
          ).adUnit;

          const videoAdUnit = "outstream_video";
          const adPlacements = [
            readingBottomProps.placement,
            midAdUnit,
            readingTopProps.placement,
            videoAdUnit, // outstream ad
            placement, // comments placement
            adUnit // inline comments placement
          ];

          this.adEligibility.forEach((adEligibility, index) => {
            const renderAd = includeAdMarkup(
              adPlacements[index],
              adData.testGroups,
              adData.context
            );
            sendAdReconciliationEvent({
              adData: { ...adData, adEligibility: adEligibility },
              skipReason: renderAd.reason
            });
          });
        }

        _.defer(function() {
          /*
         * Rendering for Inline Comments
         */
          self.model.getCommentCountByParagraph().then(function() {
            /*
           * Render only commentCounts for the paragraphs within the main story text page
           * (and not for for <p> tags inside the inline comments modal)
           */
            self.renderCommentCounts(
              self.$(".panel.panel-reading > pre p[data-p-id]")
            );
          });
          utils.resizeImages(
            self.$(".panel-reading pre [data-image-layout=one-horizontal]"),
            self.$(".panel-reading pre").width()
          );
          self.initializeVideoTracking();

          var isWriterPreview = self.isWriterPreview();

          if (isWriterPreview) {
            self.setImageModerationNotices();
          }
        });

        this.addCopyListener();
        return this;
      },

      // Render mweb mid ad unit on broken react ad
      insertMobileMidLongAd: function() {
        const adSelector = `sp${this.model.get("id")}-pg${this.model.get(
          "pageNumber"
        )}`;
        const currentPageParagraphDiv = document.getElementById(adSelector);
        const adUnit = getAdStrings(
          "mid",
          this.model.attributes,
          wattpad.user !== null,
          app.get("device").is.mobile
        ).adUnit;

        // Check if ad exist on current page return out if we do then return
        if (currentPageParagraphDiv?.querySelector(`.${adUnit}`)) {
          return;
        }

        const uniqueAdMidId = createUniqueAdId(adUnit);
        const adData = _.clone(this.model.get("adData"));

        const renderAd = includeAdMarkup(
          uniqueAdMidId,
          adData.testGroups,
          adData.context
        );

        if (!renderAd.result) {
          __atha.sendSkip(
            uniqueAdMidId,
            renderAd.reason,
            adData?.context?.story,
            adData?.context?.storyPart
          );
          utils.getCookie("debug-ads") &&
            console.debug(
              `${uniqueAdMidId}: Include ad markup false sent adskip event`
            );
        } else {
          adData.placement = uniqueAdMidId;
          const adDiv = document.createElement("div");
          adDiv.setAttribute("class", "reading-widget col-xs-12 ad-container");

          adDiv.innerHTML = `
            <h4 class="panel-title">${wattpad.utils.trans(
              "Story continues below"
            )}</h4>
            <div class="${adUnit} readinglong-mid-mweb" id="${uniqueAdMidId}"></div>
          `;
          currentPageParagraphDiv.appendChild(adDiv);
          getAd(adData);
        }
      },

      // Render the top ad unit
      renderTopAdUnit: function() {
        const $readingTopAd = partTopAd;
        const selector = "#reading-top-ad";
        const group = this.model.get("group");
        if ($(selector).length && !group.isPaywalled) {
          $(selector).replaceWith($readingTopAd(this.model.toJSON()));
        }
      },

      getParagraphById: function(paraId) {
        return this.$(
          ".panel.panel-reading > pre > p[data-p-id=" + paraId + "]"
        );
      },

      /*
    * Add event listener to disallow copy on selection containing any story content.
    * Should return false to prevent copying.
    */
      addCopyListener: function() {
        $(window).on("copy.screen_" + this.cid, function() {
          var storyContentNode = $(".part-content")[0];
          var inlineCommentTextNode = $(
            ".inline-comments-modal .paragraph-text"
          )[0];
          var disallowedNodes = [storyContentNode, inlineCommentTextNode];
          var allowCopy = !_.some(disallowedNodes, function(node) {
            return node && window.getSelection().containsNode(node, true);
          });
          if (!allowCopy) {
            return false;
          }
        });
      },

      getActivePage: function() {
        return this?.scrollState?.part[this?.scrollState?.part?.active?.id]
          ?.page?.active;
      },

      getNonFirstPartRightRail: function() {
        const rightRail = $("body")
          .find(".page:not(.first-page) .right-rail")
          .first();

        return rightRail.length === 1 ? rightRail : null;
      },

      hasMidLongAdBeenCreated: function(targetContainer) {
        return targetContainer.find(".reading-mid-ad").length > 0;
      },

      insertMidLongAdMarkup: function(targetContainer) {
        const adMarkup =
          '<div class="reading-widget reading-mid-ad sticky-ad" id="' +
          this.uniqueMidLongAdId +
          '" data-start-selector=".sticky-ad-container" data-end-selector="#sticky-end"></div>';
        targetContainer.append(adMarkup);
      },

      /**
       * We bail out when:
       * * Target DOM element isn't setup
       * * User is currently on first page (Similar stories and top ad exist here)
       * * We can't fight the .right-rail of a story part (that isn't page 1)
       *
       * We do:
       * * call getAd if refreshAd doesn't live in this object instance
       * * * and attach the return refresh function to the object instance
       * * Refresh an existing ad if it was been five seconds since its last refresh(or creation)
       * * Do nothing if the ad live time is less than 5 seconds
       */
      insertMidLongAd: function() {
        // the mid long ad hasn't been initialised, bail out.
        // User is on the first page (that uses top ad with similar stories), bail out.
        if (!this.uniqueMidLongAdId || this.getActivePage() === 1) {
          utils.getCookie("debug-ads") &&
            console.debug(
              `On first page of the chapter OR 'uniqueMidLongAdId' is not setup. Not setting up mid long ad `
            );

          return;
        }

        // some backbone wizardry;
        const self = this;
        const adData = _.clone(self.model.get("adData"));

        const renderAd = includeAdMarkup(
          this.uniqueMidLongAdId,
          adData.testGroups,
          adData.context
        );

        // if not safe to render ad then fail fast and exit out + send atha event
        if (!renderAd.result) {
          __atha.sendSkip(
            this.uniqueMidLongAdId,
            renderAd.reason,
            adData?.context?.story,
            adData?.context?.storyPart
          );
          utils.getCookie("debug-ads") &&
            console.debug(
              `${
                this.uniqueMidLongAdId
              }: Include ad markup false sent adskip event`
            );
          return;
        }

        // Get the right rail element of the first part that isn't part of the first page (direct links can SSR Page 2-n)
        const nonFirstPartRightRail = this.getNonFirstPartRightRail();

        if (nonFirstPartRightRail) {
          if (!this.hasMidLongAdBeenCreated(nonFirstPartRightRail)) {
            this.insertMidLongAdMarkup(nonFirstPartRightRail);
          } else {
            utils.getCookie("debug-ads") &&
              console.debug(
                `${
                  this.uniqueMidLongAdId
                }: Ad markup already exists. Not creating it again.`
              );
          }

          // overwrite placement with uniqueID as we're circumventing AdContainer when creating this ad.
          utils.getCookie("debug-ads") &&
            console.debug(
              `${
                this.uniqueMidLongAdId
              }: swapping placement with unique ad unit ID needed by refresh functionality`
            );
          adData.placement = this.uniqueMidLongAdId;

          if (typeof self.refreshLongMidAd !== "function") {
            utils.getCookie("debug-ads") &&
              console.debug(
                `${
                  this.uniqueMidLongAdId
                }: refresh has not been setup yet. Calling getAd and storing refresh callback in state`
              );
            self.refreshLongMidAd = getAd(adData, true);

            utils.getCookie("debug-ads") &&
              console.debug(
                `${
                  this.uniqueMidLongAdId
                }: setting timestamp for refresh check `
              );
            self.longMidAdLastRefresh = Date.now();
          } else {
            const currentTime = Date.now();

            if (
              !self.longMidAdLastRefresh ||
              currentTime - self.longMidAdLastRefresh >=
                MID_LONG_AD_REFRESH_TIME
            ) {
              utils.getCookie("debug-ads") &&
                console.debug(
                  `${this.uniqueMidLongAdId}: ${MID_LONG_AD_REFRESH_TIME /
                    1000} seconds has passed; refreshing ad `
                );

              self.refreshLongMidAd();
              utils.getCookie("debug-ads") &&
                console.debug(
                  `${this.uniqueMidLongAdId}: resetting last refresh time`
                );

              self.longMidAdLastRefresh = currentTime;
            } else {
              utils.getCookie("debug-ads") &&
                console.debug(
                  `${this.uniqueMidLongAdId}: only ${currentTime -
                    self.longMidAdLastRefresh}ms has passed, not refreshing`
                );
            }
          }
        } else {
          if (utils.getCookie("debug-ads")) {
            if (
              Date.now() - self.longMidAdLastRefresh <
              MID_LONG_AD_REFRESH_TIME
            ) {
              console.debug(
                `${this.uniqueMidLongAdId}: ${MID_LONG_AD_REFRESH_TIME /
                  1000} seconds has not passed yet. Not refreshing.`
              );
            } else {
              console.error(
                `${
                  this.uniqueMidLongAdId
                }: refresh has skipped for an unexpected reason`
              );
            }
          }
        }
      },
      /*
     * Takes an array of paragraph elements and appends a commentCount marker for each
     * (or a hidden one if no comments) on initialize & page scroll.
     */
      renderCommentCounts: function(paragraphs) {
        var self = this;
        var paragraphCommentCounts = self.model.get("paragraphs");
        const partTitle = self.model.get("title");
        const partId = self.model.get("id");
        const storyAuthor = self.model.get("group")?.user.username;
        const storyId = self.model.get("group")?.id;
        const adData = self.model.get("adData");

        /*
       * Don't render comment counts until we have already fetched paragraph/comment
       * metadata via getCommentCountByParagraph.
       */
        if (paragraphCommentCounts) {
          const deeplinkData = self.model.get("deeplinkData");
          const deeplinkOpened = self.model.get("deeplinkOpened");

          _.each(paragraphs, function(paragraph) {
            var $paragraph = $(paragraph);
            var paragraphId = $paragraph.data("p-id");
            const paragraphBody = self.getParagraphBody(paragraphId);

            let initialCommentId;

            // check if we should open the marker with deeplink
            if (!deeplinkOpened && deeplinkData?.paragraphId === paragraphId) {
              initialCommentId = deeplinkData.commentId;
              self.model.set("deeplinkOpened", true);
              app.trigger("app:newComments:deeplinkOpened");
            }

            var commentObj = _.find(paragraphCommentCounts, function(obj) {
              return obj.id === paragraphId;
            });

            const markerData = {
              adData,
              partId,
              partTitle,
              storyId,
              storyAuthor,
              paragraphId,
              paragraphBody,
              initialCommentId,
              commentCount: commentObj ? commentObj.commentCount : 0
            };

            self.renderCommentMarker($paragraph, markerData);
          });

          // verify if deeplink was opened
          if (deeplinkData?.paragraphId && !self.model.get("deeplinkOpened")) {
            // trigger load more
            if (self.model.get("pageNumber") < self.model.get("pages")) {
              self.$("footer")[0].scrollIntoView(self);
              self.$el.find(".on-load-more-page").trigger("tap");
            } else {
              // it's orphan comment
              self.model.set("deeplinkOpened", true);
              app.trigger("app:newComments:deeplinkOrphan");
            }
          }
        }
      },

      // Updates the comment count on the comment marker for each paragraph.
      renderCommentMarker: function(paragraph, markerData) {
        const self = this;
        const $paragraph = $(paragraph);
        const $commentMarker =
          self.partials["core.story_reading.comment-marker"];

        $paragraph.children(".comment-marker").remove();
        $paragraph.append($commentMarker(markerData));
      },

      initializeVideoTracking: function() {
        var self = this;

        // This method is called automatically after the Youtube iFrame API has loaded
        window.onYouTubeIframeAPIReady = function() {
          self.initializeEmbeddedIframes();
        };

        // If the Youtube iFrame hasn't been loaded yet, load it now
        if (!window.YT) {
          window.YTConfig = {
            host: "https://www.youtube-nocookie.com"
          };
          var tag = document.createElement("script");
          tag.src = "https://www.youtube.com/iframe_api";
          var firstScriptTag = document.getElementsByTagName("script")[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
      },

      initializeEmbeddedIframes: function() {
        var self = this;
        // only run if the Youtube iFrame API has been initialized
        if (window.YT) {
          $("[data-media-type=video] iframe")
            .not(".initialized")
            .each(function(index, element) {
              var player = new YT.Player(element, {
                events: {
                  onStateChange: function(data) {
                    self.trackVideoState(element, data);
                  }
                }
              });
              $(element).addClass("initialized");
            });
        }
      },

      trackVideoState: function(videoElement, videoStateData) {
        var video = $(videoElement);
        if (
          videoStateData.data === YT.PlayerState.PLAYING &&
          !video.data("playTracked")
        ) {
          window.te.push("event", "reading", "media", "video", "play", {
            partid: this.model.get("id"),
            videoid: video.data("videoId")
          });
          video.data("playTracked", 1);
        } else if (videoStateData.data === YT.PlayerState.ENDED) {
          window.te.push("event", "reading", "media", "video", "finished", {
            partid: this.model.get("id"),
            videoid: video.data("videoId")
          });
          video.data("playTracked", 0);
        }
      },

      remove: function() {
        this.ignoreEvents = true;
        $(window).off("scroll.screen_" + this.cid);
        $(window).off("copy.screen_" + this.cid);
        $(".panel-reading p img").off("error");
        //remove shadow banner
        $(".shadow-verify").remove();
        app.views.RegionManager.prototype.remove.apply(this, arguments);
        $("body").removeAttr("ondragstart onselectstart unselectable");
      },

      setRegions: function(currentPartIsBlocked = false) {
        var isPreview = this.options ? this.options.isPreview : false;
        this.$(this.regionTags.funbar).empty();

        var view = new this.subviews.funbar({
          model: this.model,
          isPreview: isPreview
        });
        this.setRegion(this.regionTags.funbar, view.render());
        var media = this.model.get("media");
        if (!media) {
          media = [];
          if (this.model.get("videoId")) {
            media.push({ video: true, ref: this.model.get("videoId") });
          }
          if (this.model.get("photoUrl")) {
            media.push({ photo: true, ref: this.model.get("photoUrl") });
          }
        }
        // Set media to null, so prefetched story detail won't show on CSR.
        this.model.set("media", null);
        this.$(this.regionTags.media).empty();
        var isWriterPreview = this.isWriterPreview();

        view = new this.subviews.media({
          model: this.model,
          media: media,
          bgCover: this.model.get("group").cover,
          socialLinks: this.socialLinks,
          isWriterPreview: isWriterPreview
        });
        this.setRegion(this.regionTags.media, view.render());

        //Show bottom banner when all conditions satisfied:
        //desktop, loggout user, not AdExempt, not force signup exempt, and
        //haven't dismissed the banner in current session.
        if (
          app.get("device").is.desktop &&
          !wattpad.utils.currentUser().authenticated() &&
          !this.model.get("group").isAdExempt &&
          !wattpad.utils.getCookie("dB")
        ) {
          this.$(this.regionTags.bottomBanner).empty();
          var view = new this.subviews.bottomBanner({
            image: this.model.get("group").cover,
            title: this.model.get("group").title,
            showBottomBanner: true,
            dismissibleBanner: true
          });
          this.setRegion(this.regionTags.bottomBanner, view.render());
        }
      },

      onUpdateVote: function(data) {
        // runs on app.trigger app:story:vote event
        if (!data || data.storyId !== this.model.get("id")) {
          return;
        }
        if (data.voted) {
          this.voteSelected();
        } else {
          this.voteDeselected();
        }
        this.updateMeta(data.voteCount);
      },

      voteSelected: function() {
        var newIcon = wattpad.utils.iconify("fa-vote", 16, "wp-base-1"),
          newText = wattpad.utils.trans("Voted");
        this.$(".actions > .on-vote").html(newIcon + " " + newText);
      },

      voteDeselected: function() {
        var newIcon = wattpad.utils.iconify("fa-vote", 16, "wp-neutral-2"),
          newText = wattpad.utils.trans("Vote");
        this.$(".actions > .on-vote").html(newIcon + " " + newText);
      },

      updateMeta: function(voteCount) {
        this.$(".story-stats > .reads").html(
          wattpad.utils.iconify("fa-view", 14, "wp-neutral-2") +
            " " +
            this.model.get("readCount")
        );
        this.$(".story-stats > .votes").html(
          wattpad.utils.iconify("fa-vote", 14, "wp-neutral-2") +
            " " +
            (voteCount ? voteCount : this.model.get("voteCount"))
        );
        this.$(".story-stats > .comments").html(
          wattpad.utils.iconify("fa-comment", 14, "wp-neutral-2") +
            " " +
            this.model.get("commentCount")
        );
      },

      // Return isBigPart for DW users
      getIsBigPart: function() {
        return this.model.get("pages") >= 12;
      },

      onLoadNextPage: function(event) {
        this.unlockScrollUpdate();
        var page = parseInt(
          $(event.currentTarget)
            .attr("href")
            .match(/page\/([0-9]+)$/)[1],
          10
        );
        this.loadNextPage(page);
      },

      loadNextPage: function(pageNumber) {
        if (pageNumber > this.model.get("pages")) {
          this.$(".load-more-page").addClass("hidden");
          this.$(".next-part").removeClass("hidden");
          return;
        }

        var self = this,
          activePart = this.scrollState.part[this.scrollState.part.active.id],
          lastPage = activePart.page.last.$el,
          device = app.get("device"),
          textPartial = self.partials["core.story_reading.part_text_container"];

        this.model.getPage(pageNumber).then(function(page) {
          if (
            !page.get("text") &&
            (!page.get("paragraphs") || _.isEmpty(page.get("paragraphs")))
          ) {
            if (pageNumber <= self.model.get("pages")) {
              self.$(".load-more-page").removeClass("hidden");
              self.$(".next-part").addClass("hidden");
            }
            return;
          }

          var nextPageVariables = {
            id: self.model.get("id"),
            pages: self.model.get("pages"),
            group: self.model.get("group"),
            text: page.get("text"),
            pageNumber: pageNumber,
            firstPage: pageNumber === 1,
            lastPage: pageNumber >= self.model.get("pages"),
            isMicroPart: self.model.get("pages") < 2,
            isSmallPart:
              self.model.get("pages") >= 2 && self.model.get("pages") < 5,
            isMediumPart:
              self.model.get("pages") >= 5 && self.model.get("pages") < 12,
            isBigPart: self.getIsBigPart(),
            isHeightZero: true
          };

          var $nextPage = $(
            textPartial(_.extend({}, self.model.toJSON(), nextPageVariables))
          );

          self
            .$(".on-load-more-page")
            .attr("href", activePart.url + "/page/" + (pageNumber + 1));

          if (lastPage.next(".mobile-cta").length) {
            lastPage = lastPage.next(".mobile-cta");
          }

          lastPage.after($nextPage.removeClass("height-zero"));

          /*
               * Fetch inline comment counts & open Inline Comments Modal for the next page
               */

          self.renderCommentCounts(
            $nextPage.find(".panel.panel-reading > pre p[data-p-id]")
          );

          self.initializeEmbeddedIframes();
          // Setting up a watch for campfire image loading errors.
          $(".panel-reading p img").on(
            "error",
            _.bind(self.onImageLoadError, self)
          );
        });
      },

      setElement: function() {
        Monaco.View.prototype.setElement.apply(this, arguments);

        _.each(
          this.regionTags,
          function(regionTag) {
            if (this.regions[regionTag] && this.regions[regionTag].view) {
              if (this.$(regionTag).length > 0) {
                this.regions[regionTag].view.setElement(
                  $(this.$(regionTag + " > div"))
                );
                if (
                  regionTag === this.regionTags.media &&
                  this.regions[regionTag].view.media.length > 1
                ) {
                  this.regions[regionTag].view.showNextArrow();
                }
              }
            }
          },
          this
        );
      },

      getImageModerationStatus: function(imageUrl) {
        return Promise.resolve(
          $.ajax({
            type: "GET",
            url: "/v5/image_moderation/result?image_url=" + imageUrl
          })
        )
          .then(function(response) {
            return response.result;
          })
          .catch(function(error) {
            return (
              "Error fetching image moderation result:" + error.responseText
            );
          });
      },

      isWriterPreview: function() {
        return (
          wattpad.utils.currentUser() &&
          this.model.get("group").user.username ===
            wattpad.utils.currentUser().get("username")
        );
      },

      setImageModerationNotices: function() {
        // select all images inline in text
        // send image url to v5 for moderation status
        // if status is banned. then show image rejection overlay
        var self = this;
        $("[data-image-layout=one-horizontal]").each(function(
          index,
          parentElement
        ) {
          var $imageParent = $(parentElement),
            imageSrc = $(parentElement)
              .find("img")
              .attr("src")
              .split("?")[0]; //without the query params

          self
            .getImageModerationStatus(imageSrc)
            .then(function(result) {
              if (result !== "banned") {
                return;
              }
              var groupId = self.model.storyId,
                partId = self.model.partId;

              $imageParent
                .addClass("banned")
                .find(".banned-overlay")
                .removeClass("hidden");

              $imageParent
                .find(".banned-messaging")
                .removeClass("hide")
                .append(
                  self.partials["core.banned_image_messaging"]({
                    id: $imageParent.data("p-id"),
                    src: imageSrc,
                    groupId: groupId,
                    partId: partId
                  })
                );
            })
            .catch(function(error) {
              console.error(error);
            });
        });
      },

      onPageScroll: function() {
        if (this.ignoreEvents) {
          return;
        }
        this.updateScrollState();
      },

      delegateScrollEvent: _.throttle(function() {
        this.onPageScroll();
      }, 200),

      // Similar to lockScrollUpdate, but not meant to be used in a pair with
      // unlockScrollUpdate; this is a one-way transition.
      stopScrollUpdate: function() {
        this.ignoreEvents = true;
        $(window).off("scroll.screen_" + this.cid);
      },

      lockScrollUpdate: function() {
        this.ignoreEvents = true;
        $(window).off("scroll.screen_" + this.cid);

        var activePart = this.scrollState.part[this.scrollState.part.active.id];
        if (activePart.page.last.number < this.model.get("pages")) {
          this.$(".load-more-page").removeClass("hidden");
        }
      },

      unlockScrollUpdate: function(evt) {
        var self = this;
        this.ignoreEvents = false;
        $(window).on("scroll.screen_" + this.cid, function(e) {
          self.delegateScrollEvent(e);
        });

        this.onPageScroll();
      },

      _calculatePercentageRead: function($activePage) {
        // First, calculate the percentage of the pages we've read.
        var pageNum = $activePage.data("page-number");
        var numPages = this.model.get("pages");
        var $readingPanel = $activePage.find(".panel-reading");
        var percentage = (pageNum - 1) / this.model.get("pages") || 0;

        const readingPanelOffset = $readingPanel.offset();
        // In some cases, e.g. on a paywalled part, there is no
        // reading panel, so count it as not read.
        if (!$readingPanel.offset()) {
          return 0;
        }

        // Then, add the percentage of this page we've read.
        var diff = window.scrollY - readingPanelOffset.top;
        var pagePercent = $readingPanel.height()
          ? diff / $readingPanel.height()
          : 0;
        percentage += pagePercent / numPages || 0;

        // We need to clamp to [0, 100%].
        return Math.min(1, Math.max(0, percentage));
      },

      updateScrollState: function(fromController) {
        fromController = fromController || false;

        var $activePart, $activePage, $parts, $pages;

        $parts = this.$(".story-part"); // Get all parts
        $activePart = this.findViewable($parts); // Get active part

        $pages = $activePart.find(".page"); // Get pages within active part
        $activePage = this.findViewable($pages); // Get active page

        if (
          _.isEmpty(this.scrollState.part) ||
          this.scrollState.part.active.id !== $activePart.data("part-id")
        ) {
          this.scrollState.part = {
            count: $parts.length,
            active: { id: $activePart.data("part-id") }
          };
        }

        this.model.trigger(
          "reading-progress",
          this._calculatePercentageRead($activePage)
        );

        if (
          !this.scrollState.part[$activePart.data("part-id")] ||
          this.scrollState.part[$activePart.data("part-id")].page.active !==
            $activePage.data("page-number")
        ) {
          this.scrollState.part[$activePart.data("part-id")] = {
            url: $activePart.data("part-url"),
            page: {
              count: $pages.length,
              active: $activePage.data("page-number"),
              first: $pages.first().data("page-number"),
              last: {
                number: $pages.last().data("page-number"),
                $el: $pages.last()
              }
            }
          };

          this.model.set(
            "pageNumber",
            this.scrollState.part[$activePart.data("part-id")].page.active
          );

          this.updateUrl();
          this.updateTitle();
          if (!fromController) {
            wattpad.utils.pushEvent({}, "virtualPageview");
          }

          this.updateSyncPosition();
          this.updatePageContent();
          // if current page is not first page run reading long dweb
          if ($activePage.data("page-number") !== 1) {
            this.sendReadingEvent();
            if (app.get("device").is.desktop) {
              this.insertMidLongAd();
            }
          }
          // If current page is not last page run reading long mweb
          if ($activePage.data("page-number") < this.model.get("pages")) {
            if (app.get("device").is.mobile) {
              this.insertMobileMidLongAd();
            }
          }
        }
      },

      updateUrl: function() {
        if (!wattpad.utils.supportPushState()) {
          return;
        }

        var url = "",
          activePart = this.scrollState.part[this.scrollState.part.active.id],
          activePage = activePart.page.active,
          commentIndex = window.location.href.indexOf("/comment");

        // Extract and build URL
        url += wattpad.utils.formatStoryUrl(activePart.url);
        url += activePage > 1 ? "/page/" + activePage : "";
        url +=
          commentIndex !== -1 ? window.location.href.slice(commentIndex) : "";
        url += window.location.search;

        if (app.get("device").is.desktop) {
          var pageData = this.model.toJSON();
          app.trigger("checkRefreshingAds", pageData);
        }
        // Execute url change
        app.router.navigate(url, { trigger: false, replace: true });
      },

      updateTitle: function() {
        var storyGroupTitle = this.model.get("group").title,
          storyPartTitle = this.model.get("title"),
          activePart = this.scrollState.part[this.scrollState.part.active.id],
          activePage = activePart.page.active;

        var docTitle = storyGroupTitle;
        docTitle +=
          storyGroupTitle !== storyPartTitle ? " - " + storyPartTitle : "";
        if (activePage > 1) {
          docTitle += " - " + wattpad.utils.trans("Page") + " " + activePage;
        }

        var title;

        // Vietnamese is special-cased to deal with scrapers with good SEO
        var lang = parseInt(app.get("language"), 10);
        if (lang === 19) {
          title =
            "Đọc Truyện " +
            docTitle +
            " - " +
            this.model.get("group").user.name +
            " - Wattpad";
        } else {
          title = docTitle;
        }

        wattpad.utils.setTitle(title);
      },

      updatePageContent: function() {
        //TODO: Add content while scrolling
        var activePart = this.scrollState.part[this.scrollState.part.active.id],
          activePage = activePart.page.active;

        //Check for next page
        if (
          activePage <= this.model.get("pages") &&
          activePart.page.last.number === activePage
        ) {
          this.loadNextPage(parseInt(activePage, 10) + 1);
        }
      },

      _calculateSyncPosition: function() {
        var activePage = this.scrollState.part[this.scrollState.part.active.id]
            .page.active,
          percentage = activePage / this.model.get("pages") || 0;

        return Number(Math.round(percentage + "e3") + "e-3"); //round to 3 decimal places
      },

      updateSyncPosition: function() {
        var currentPercentRead = 0;

        //Disable entire method when syncing is not enabled
        if (this.sync.enabled) {
          currentPercentRead = this._calculateSyncPosition();

          //Only sync if we are further in the story
          if (currentPercentRead > this.sync.last) {
            this.sync.last = currentPercentRead;
            this.model.syncPosition(currentPercentRead);

            //Disable syncing when we have finished
            if (currentPercentRead === 1) {
              this.sync.enabled = false;
            }
          }
        }
      },

      sendReadingEvent: function() {
        var group = this.model.get("group"),
          publishedParts = utils.publishedPartsInStory(group);

        window.te.push("event", "reading", "progress", null, "next_page", {
          storyid: group.id,
          partid: this.model.get("id"),
          read_percent: this._calculateSyncPosition(),
          published_parts: publishedParts.length
        });
      },

      findViewable: function($vector) {
        var result = $($vector[0]), // Default to first element in vector
          threshold = window.innerHeight / 2 + window.scrollY; // Our threshold is 1/2 down the screen

        // Only 1 element, or screen not scrolled
        if ($vector.length < 2 || window.scrollY === 0) {
          return result;
        }

        // Iterate through all the elements in the vector
        $vector.each(function(index) {
          var $current = $(this),
            $next = $vector[index + 1] ? $($vector[index + 1]) : null; // Next element in the vector

          result = $current;

          if (result.height() === 0) {
            // If the next element is below our threshold, stop iterating and return the current element
            if (
              $next &&
              $next.find(".panel-reading").offset().top > threshold
            ) {
              return false;
            }
          }

          // If the next element is below our threshold, stop iterating and return the current element
          if ($next && $next.offset().top > threshold) {
            return false;
          }
        });

        return result;
      },

      //For the LibraryManagement mixin...we implement this here because it's shared
      //and there's a requirement that mixins be mixed into the extended children
      onAddedToLibrary: function(storyId) {
        var $buttons = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $buttons
          .children(".fa")
          .addClass("fa-check")
          .removeClass("fa-plus");
        $buttons
          .children(".btn-library-text")
          .html(wattpad.utils.trans("In Library"));
      },

      onRemovedFromLibrary: function(storyId) {
        var $buttons = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $buttons
          .children(".fa")
          .removeClass("fa-check")
          .addClass("fa-plus");
        $buttons
          .children(".btn-library-text")
          .html(wattpad.utils.trans("Add to Library"));
      },

      onSocialShareSelected: function(evt) {
        wattpad.utils.pushEvent({
          category: "reading",
          action: $(evt.currentTarget).data("gtm-action"),
          label: this.model.get("id")
        });

        window.te.push("event", "reading", "story", null, "share", {
          storyid: this.model.get("group").id,
          partid: this.model.get("id"),
          channel: $(evt.currentTarget).data("share-channel")
        });
      },

      getDeepLinkPage: function() {
        return "story-reading";
      },

      onSendToFriend: function(evt) {
        var value = this.$(".send-input")
          .val()
          .trim();
        var $button = $(".on-send").attr("disabled", "disabled");
        $button.text(utils.trans("Sending"));
        Promise.resolve(
          $.ajax({
            type: "POST",
            url:
              "/v4/stories/" +
              this.model.get("group").id +
              "/parts/" +
              this.model.get("id") +
              "/share",
            data: { target: value }
          })
        )
          .then(function() {
            $(".on-send").removeAttr("disabled");
            $button.text(utils.trans("Sent"));
            _.delay(function() {
              $button.text(utils.trans("Send to Friend"));
            }, 5000);
          })
          ["catch"](function(resp) {
            // todo: error message
            $(".on-send").removeAttr("disabled");
            window.alert(wattpad.utils.trans(resp));
          });
      },

      onValidateSend: function(evt) {
        var value = $(evt.currentTarget).val();

        if (
          !!value.match(/[0-9-\(\)+]{10,}/) ||
          value.match(/[^<>"']+@[^<>"']+\.[^<>"']{2,}/)
        ) {
          $(".on-send").removeAttr("disabled");
        } else {
          $(".on-send").attr("disabled", "disabled");
        }
      },

      onImageLoadError: function(evt) {
        window.te.push("event", "reading", "media", null, "empty", {
          storyid: this.model.get("group").id,
          partid: this.model.get("id"),
          image_url: evt.currentTarget.getAttribute("src"),
          user_agent: wattpad.utils.getUserAgent(100)
        });
      },

      onShowFullSizeImage: function(evt) {
        if (
          $(evt.currentTarget)
            .parent()
            .parent()
            .hasClass("author")
        ) {
          return;
        }

        var viewerContents = $(evt.currentTarget)
          .removeAttr("width height")
          .addClass("zoomed-image")[0].outerHTML;

        var pictureViewer = new app.views.PictureViewer({
          contents: viewerContents,
          paragraph: $(evt.currentTarget).closest("p"),
          mediaShare: this.mediaShare,
          partModel: this.model
        });

        var modalContents = pictureViewer.render().$el.html();

        if ($("#picture-viewer").length === 0) {
          $("#modals").append(modalContents);
        } else {
          $("#picture-viewer").replaceWith(modalContents);
        }

        pictureViewer.setElement($("#picture-viewer"));

        window.te.push("event", "reading", "media", null, "expand", {
          partid: this.model.get("id")
        });

        $("#picture-viewer").modal();
        $(".modal-backdrop").addClass("black");
      },

      onFullSizeBanner: function(evt) {
        var viewerContents = $(evt.currentTarget)
            .html()
            .trim(),
          replaceWith =
            "h=" + $(window).height() + "&amp;w=" + $(window).width() + "&amp;";

        viewerContents = viewerContents.replace(
          /h=\d+&amp;w=\d+&amp;/,
          replaceWith
        );

        var pictureViewer = new app.views.PictureViewer({
          contents: viewerContents,
          paragraph: $(evt.currentTarget).closest("p")
        });

        var modalContents = pictureViewer.render().$el.html();

        if ($("#picture-viewer").length === 0) {
          $("#modals").append(modalContents);
        } else {
          $("#picture-viewer").replaceWith(modalContents);
        }

        pictureViewer.setElement($("#picture-viewer"));

        window.te.push("event", "reading", "media", null, "expand", {
          partid: this.model.get("id")
        });

        $("#picture-viewer").modal();
        $(".modal-backdrop").addClass("black");
      },

      trackPurchaseFailed: function(storyId, partId, price, partIndex) {
        var wallet = window.store.getState().wallet;

        window.te.push("event", "paywall", "purchase", null, "failed", {
          page: "reading",
          storyid: storyId,
          partid: partId,
          cost: price,
          starting_balance: wallet.amount,
          part_index: partIndex
        });
      },

      buyPaidContent: function(price, storyId, partId, partIndex) {
        var wallet = window.store.getState().wallet,
          currencyId = wallet.id,
          startingBalance = wallet.amount;

        window.te.push("event", "paywall", "purchase", null, "start", {
          page: "reading",
          storyid: storyId,
          partid: partId,
          cost: price,
          starting_balance: startingBalance,
          part_index: partIndex
        });

        var parts = this.model.get("group").parts || [];
        var partIds = _.pluck(parts, "id");
        return utils
          .buyPaidContent(currencyId, storyId, partId)
          .then(
            function() {
              // Use the balance at the start of purchase, not the
              // post-purchase balance, for the event.
              window.te.push("event", "paywall", "purchase", null, "complete", {
                page: "reading",
                storyid: storyId,
                partid: partId,
                cost: price,
                starting_balance: startingBalance,
                part_index: partIndex
              });

              // Cache bust everything
              return utils.cacheBustPaidMetadata(storyId, partIds);
            }.bind(this)
          )
          .catch(
            function(err) {
              this.trackPurchaseFailed(storyId, partId, price, partIndex);

              utils.showToast(err);

              // Purchase should have been prevented before the API call if balance too low
              // Wallet is probably out of sync
              window.store.dispatch(
                window.app.components.actions.fetchWalletBalance(
                  wattpad.user.username,
                  true
                )
              );

              throw err;
            }.bind(this)
          );
      },

      onBuyCoins: function(blockedPart) {
        $("#generic-modal").modal("hide");

        setTimeout(
          function() {
            const story = this.model.get("group");
            const buyCoinsModal = utils.getModal(MODAL_ID.BUY_COINS_MODAL);
            buyCoinsModal.setData({
              device: app.get("device"),
              appUrl: utils.getAppUrl(),
              page: "reading",
              storyId: story?.id,
              blockedPart: blockedPart?.id
            });

            buyCoinsModal.show();
          }.bind(this),
          500
        );
      },

      showStoryPaywallModal: function(blockedPart) {
        const group = this.model.get("group");

        window.te.push("event", "paywall", "author", null, "view", {
          page: "reading",
          storyid: parseInt(group.id, 10),
          partid: blockedPart.id,
          author: group.user.name,
          part_index: utils.getIndexForPartId(blockedPart.id, group)
        });

        var storyPaywallModal = new app.views.DummyReactView({
          component: "StoryPaywallModal",
          componentId: group.id,
          componentData: {
            group: group,
            authorMessage: group.user.authorMessage,
            onBuyCoins: () => this.onBuyCoins(blockedPart)
          }
        });
        storyPaywallModal.render();

        $("#generic-modal .modal-body").html(storyPaywallModal.$el);
        $("#generic-modal").modal("show");
      },

      processPaidMetadata: function(data, paidMetadata) {
        // Process and set values for paid metadata, for easy use in
        // the reader template.
        const {
          id: partId,
          group: { id: storyId }
        } = data;

        // Normalize parts (first so isBlocked can be used below)
        data.group.parts = data.group.parts.map(part => {
          let partMetadata = paidMetadata[storyId].parts.find(
            p => part.id === p.id
          );
          if (!partMetadata) return part;

          part = {
            ...part,
            ...partMetadata
          };

          part.wordCount = this.model.get("wordCount");
          part.commentCount = this.model.get("commentCount");

          // Could include multiple currencies - default to first
          const priceObj = _.first(partMetadata.price);
          if (priceObj) {
            part.price = priceObj.amount;
          }

          return part;
        });

        data.isSubscriptionCoinsTestGroup =
          wattpad.testGroups.SUBSCRIPTION_COINS_1;

        // Process top-level fields
        const priceMetadata = _.first(paidMetadata[storyId].story.price);
        if (priceMetadata) {
          data.storyPrice = priceMetadata.amount;
        }

        data.remainingBlockedParts = _.filter(data.group.parts, function(part) {
          return part.isBlocked;
        }).length;

        const currentIndex = _.findIndex(data.group.parts, { id: partId });
        const currentPart = data.group.parts[currentIndex];
        let nextPart;
        if (currentIndex < data.group.parts.length - 1) {
          nextPart = data.group.parts[currentIndex + 1];

          // Unblock if the next part is a bonus chapter [FAN-1510]
          if (nextPart.isBonusPart) {
            nextPart.isBlocked = false;
          }
        }

        data.freePartsUntilPaywall = utils.numPartsBetweenCurrentAndPaywall(
          data.group.parts,
          currentPart
        );

        data.isPaidPreview = paidMetadata[storyId].story.isPaidPreview;

        data.currentPartIsBlocked = currentPart.isBlocked;

        data.blockedPart = data.currentPartIsBlocked ? currentPart : nextPart;

        data.part = currentPart;

        data.isPaidStory = wattpad.utils.isPaidStory(data.group);

        data.isBonusPart = data.part.isBonusPart;

        // Add event handlers
        data.buyPaidContent = this.buyPaidContent;
        data.showStoryPaywallModal = () =>
          this.showStoryPaywallModal(data.blockedPart);
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
