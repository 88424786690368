import oneHorizontalActive from "core/templates/story-reading/one-horizontal-active.hbs";
import inlineVideo from "core/templates/story-reading/inline-video.hbs";
import videoYoutube from "core/templates/story-reading/video-youtube.hbs";

import Icons from "../../../assets/icons";
import Swatch from "../../../assets/swatch";
import generateContainerId from "../../../helpers/generate-container-id";
import getOutstreamAdvertisement from "../../../helpers/ads/get-outstream-advertisement";

(function(window, Handlebars, _, wattpad) {
  "use strict";

  window.wattpad = wattpad || {};
  var utils = window.wattpad.utils || (window.wattpad.utils = {});

  var checkModuleIndex = function(index, deviceType) {
    if (deviceType === "mobile") {
      return index === 2 || (index % 11 === 2 && index !== 2);
    } else {
      return index === 5 || (index % 24 === 5 && index !== 5);
    }
  };

  /**
   * trans: is used to translate "text" from one language to another (embeddable in templates)
   *
   * @param text
   * @param string [ text || translated text if available ]
   */
  utils.trans = function(text) {
    var args = [];
    if (arguments.length > 1) {
      args = Array.prototype.slice.call(arguments, 1, arguments.length);
    }

    if (parseInt(app.get("language"), 10) === 1) {
      return window.vsprintf(text, args);
    }

    var trans = app.get("translatedLanguage");

    if (!trans || !trans.jed) {
      // XXX very bad: we'll just return english if the translation hasn't been loaded!
      return window.vsprintf(text, args);
    } else {
      return window.vsprintf(trans.jed.gettext(text), args);
    }
  };
  utils.trans.map = function(word) {
    return utils.trans(word);
  };

  Handlebars.registerHelper("trans", function(text) {
    var args = arguments;

    if (arguments.length > 1) {
      if (_.isObject(arguments[arguments.length - 1])) {
        args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
      }
    }

    return utils.trans.apply(this, args);
  });

  Handlebars.registerHelper("canTrans", function(text, options) {
    var trans = app.get("translatedLanguage");
    var lang = parseInt(app.get("language"), 10);

    if (
      lang === 1 ||
      (trans && trans.jed && trans.jed.options.locale_data.messages[text])
    ) {
      return options.fn(this);
    }

    return options.inverse(this);
  });

  utils.ifequal = function(val1, val2, options) {
    if (_.isEqual(val1, val2)) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("ifequal", utils.ifequal);

  utils.isCurrentUser = function(username, options) {
    var currentUser = utils.currentUser().get("username");
    return username === currentUser ? options.fn(this) : options.inverse(this);
  };
  Handlebars.registerHelper("isCurrentUser", utils.isCurrentUser);

  utils.getCurrentUserAttr = function(attribute, options) {
    return utils.currentUser().get(attribute) || "";
  };
  Handlebars.registerHelper("getCurrentUserAttr", utils.getCurrentUserAttr);

  utils.getSelectedPremiumTheme = function() {
    return parseInt(utils.getCookie("premium-theme") || 5); // default to "water"
  };
  Handlebars.registerHelper(
    "getSelectedPremiumTheme",
    utils.getSelectedPremiumTheme
  );

  utils.setPremiumTheme = function(themeId) {
    var $body = $("body");
    $body
      .removeClass(function(index, classes) {
        // Strip any "theme-#" classes so only one theme will be applied
        return (classes.match(/(^|\s)theme-\S+/g) || []).join(" ");
      })
      .addClass("theme-" + themeId);
    utils.setCookie("premium-theme", themeId);
  };

  // True iff the current user should see premium features
  Handlebars.registerHelper("isPremiumEligible", function(options) {
    if (utils.getCurrentUserAttr("isPremium")) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  });

  utils.isLoggedIn = function(options) {
    var user = utils.currentUser().get("username");
    if (typeof user !== "undefined" && user !== null) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("isLoggedIn", utils.isLoggedIn);

  utils.isAmbassador = function(options) {
    if (utils.currentUser().get("ambassador")) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("isAmbassador", utils.isAmbassador);

  utils.isAdmin = function(options) {
    if (utils.currentUser().get("isSysAdmin")) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("isAdmin", utils.isAdmin);

  utils.isWattpad = function(username, options) {
    username = username ? username : "";

    if (username.toLowerCase() === "wattpad") {
      return options.fn(this);
    }

    return options.inverse(this);
  };
  Handlebars.registerHelper("isWattpad", utils.isWattpad);

  /**
   * Determine if current device is desktop, mobile or tablet
   * Use this method like a if statement in the handlebars
   * @param devicename pass in 'desktop' 'tablet' or 'mobile'
   */
  utils.isDevice = function(devicename, options) {
    devicename = devicename ? devicename : "";
    var device = app.get("device");

    if (devicename === "desktop" && device.is.desktop) {
      return options.fn(this);
    } else if (devicename === "tablet" && device.is.tablet) {
      return options.fn(this);
    } else if (devicename === "mobile" && device.is.mobile) {
      return options.fn(this);
    }
    return options.inverse(this);
  };
  Handlebars.registerHelper("isDevice", utils.isDevice);

  utils.getDeviceType = function(options) {
    var device = app.get("device");
    if (device && device.is.mobile) return "mobile";
    if (device && device.is.tablet) return "tablet";
    if (device && device.is.desktop) return "desktop";
  };
  Handlebars.registerHelper("getDeviceType", function(options) {
    return new Handlebars.SafeString(utils.getDeviceType(options));
  });

  /**
   * count: is used to shorten numbers into thousands or millions to reduce their display size
   *
   * @param value
   * @param integer [ value || value to be shortened ]
   */
  utils.count = function(value) {
    // if readcount isn't available (server issue), render a dash instead of an incorrect number
    if (value == -1) {
      return "-";
    }
    if (!value || !_.isNumber(value)) {
      return 0;
    }
    if (value >= 99999999) {
      return Math.floor(value / 1000000) + "M";
    } else if (value >= 1000000) {
      return Math.floor(value / 100000) / 10 + "M";
    } else if (value >= 99999) {
      return Math.floor(value / 1000) + "K";
    } else if (value >= 1000) {
      return Math.floor(value / 100) / 10 + "K";
    } else {
      return value;
    }
  };
  Handlebars.registerHelper("count", utils.count);

  Handlebars.registerHelper("numberedLoop", function(collection, block) {
    var iterations = collection.length;
    var content = "";

    for (var i = 0; i < iterations; i++) {
      collection[i].position = i + 1;
      collection[i].oddOrEven = collection[i].position % 2 ? "odd" : "even";
      content += block.fn(collection[i]);
    }
    return content;
  });

  utils.resize = function(collection, url, width, re, correctionBase) {
    var newWidth = width,
      correction = correctionBase ? correctionBase[0] : false;

    if (
      !collection ||
      !url ||
      typeof url !== "string" ||
      !re ||
      !isFinite(String(width))
    ) {
      return "";
    }

    if (utils.pixelRatio(2.5)) {
      width *= 3;
      correction = correctionBase ? correctionBase[2] : false;
      correction = correctionBase ? correctionBase[1] : correctionBase;
    } else if (utils.pixelRatio(1.5)) {
      width *= 2;
      correction = correctionBase ? correctionBase[1] : false;
    }

    newWidth = _.find(
      collection,
      function(size) {
        return (
          size + (correction !== false ? Math.floor(size / correction) : 0) >=
          width
        );
      },
      this
    );

    if (!newWidth) {
      newWidth = _.reduce(collection, function(prev, current) {
        return Math.abs(current - width) < Math.abs(prev - width)
          ? current
          : prev;
      });
    }

    return url.replace(re, "$1" + newWidth + "$3$4");
  };

  utils.resizeAvatar = function(avatarUrl, width) {
    // regular expression based on user avatar url
    var re = /(^.*\/useravatar\/[\w\-_]+\.)([0-9]{1,3})(\.?[0-9]*\.)(png|jpg|jpeg|gif)$/;
    var correctionBase = [24, 17];

    if (typeof avatarUrl === "string" && avatarUrl.length > 0) {
      return utils.resize(
        window.wattpad.avatarSizes,
        avatarUrl,
        width,
        re,
        correctionBase
      );
    }

    return "";
  };
  Handlebars.registerHelper("resizeAvatar", utils.resizeAvatar);

  utils.resizeCover = function(coverUrl, width) {
    // regular expression based on story cover url
    var re = /(^.*\/c?cover\/[0-9]+\-)([0-9]{1,3})(\-*[\w]+\.)(png|jpg|jpeg|gif)$/;
    var correctionBase = [20, 14];
    return utils.resize(
      window.wattpad.coverSizes,
      coverUrl,
      width,
      re,
      correctionBase
    );
  };
  Handlebars.registerHelper("resizeCover", utils.resizeCover);

  utils.resizeCoverAsBackground = function(coverUrl, width) {
    if (!width || !isFinite(width)) {
      width = 640;
    }
    // regular expression based on story cover url
    var re = /(^.*\/cover\/[0-9]+\-)([0-9]{1,3})(\-[\w]+\.)(png|jpg|jpeg|gif)$/;
    return utils
      .resize(window.wattpad.backgroundSizes, coverUrl, width, re, false)
      .replace("cover", "bcover");
  };
  Handlebars.registerHelper(
    "resizeCoverAsBackground",
    utils.resizeCoverAsBackground
  );

  utils.profileBackground = function(url, width) {
    if (!width || !isFinite(width)) {
      width = 320;
    }
    // We need to add an extra dot(.) char at the 2nd last position so that there is a location to inject the width
    url = url.replace(/(\d+\.)(png|jpg|jpeg|gif)$/, ".$1$2");
    var re = /(^.*\/userbg\/[\w\-_]+\.)()(\.?[0-9]*\.)(png|jpg|jpeg|gif)$/;
    return utils
      .resize([320, 360, 640, 1920], url, width, re, false)
      .replace("userbg", "userbgs");
  };
  Handlebars.registerHelper("profileBackground", utils.profileBackground);

  utils.resizeLogo = function(logoUrl, width) {
    // regular expression based on logo url
    var re = /(^.*\/img\/mobile-web\/wattpad_logo_|.*\/img\/mobile-web\/white_wattpad_logo_)([0-9]{3})(\.)(png)$/;
    var correctionBase = false; //[ 20, 14 ];
    return utils.resize(
      window.wattpad.logoSizes,
      logoUrl,
      width,
      re,
      correctionBase
    );
  };
  Handlebars.registerHelper("resizeLogo", utils.resizeLogo);

  utils.resizeAuthLogo = function(logoUrl) {
    var re = /(^.*\/img\/homepages\/logo_Wattpad)(\.)(png)$/;

    if (utils.pixelRatio() > 1) {
      return logoUrl.replace(re, "$1" + "@2x" + "$2$3");
    }
    return logoUrl;
  };

  Handlebars.registerHelper("resizeAuthLogo", utils.resizeAuthLogo);

  utils.getStoryRating = function(rating) {
    var ratings = ["", utils.trans("Mature")];
    return rating < 4 ? ratings[0] : ratings[1];
  };
  Handlebars.registerHelper("getStoryRating", utils.getStoryRating);

  utils.slugify = function(text, strict) {
    if (!text) {
      return null;
    }
    text = text.replace(
      strict ? /[^\-a-zA-Z0-9\s]+/gi : /[^\-a-zA-Z0-9,&\s]+/gi,
      ""
    );
    text = text.replace(/\s+/gi, "-");
    text = text.replace(/-+/gi, "-");
    return text.trim().toLowerCase();
  };

  Handlebars.registerHelper("slugify", function(text) {
    if (_.isFunction(utils[text])) {
      var args = Array.prototype.slice.call(arguments, 1);
      text = utils[text].apply(this, args);
    }
    return utils.slugify(text);
  });

  utils.iconify = function(
    iconName,
    height,
    color,
    className,
    passthroughProps = ""
  ) {
    var isNewIcon = iconName.split("-")[0] !== "fa";
    if (isNewIcon) {
      if (Icons[iconName]) {
        return `<svg
          ${passthroughProps}
          width="${height}"
          height="${height}"
          viewBox="0 0 24 24"
          fill="none"
          stroke="${Swatch[color]}"
          stroke-width="2"
          aria-hidden="true"
          stroke-linecap="round"
          stroke-linejoin="round"
          ${className ? ` class="${className}"` : ""}
        >
          ${Icons[iconName]}
        </svg>`;
      } else {
        throw "Icon " + iconName + " doesn't exist";
      }
    }

    if (typeof iconName !== "string" || iconName.trim().length <= 0) {
      return;
    }
    height = typeof height === "number" && height > 0 ? height : "auto";
    color = typeof color === "string" ? color : "wp-neutral-2";
    className = typeof className === "string" ? className : "";

    // generate elements
    var elementClass = "";
    if (utils.modernizr("fontface")) {
      elementClass = "fa " + iconName + " fa-" + color + " " + className;
      return (
        '<span class="' +
        elementClass +
        '" aria-hidden="true"' +
        ' style="font-size:' +
        height +
        'px;"></span>'
      );
    } else {
      var iconDir;
      iconDir = "/img/icons/" + color + "/" + iconName.substr(3) + ".png"; //strip out leading "fa-" for now
      elementClass = "fa " + iconName + " fallback " + className;

      return (
        '<img class="' +
        elementClass +
        '"' +
        ' style="height:' +
        height +
        'px !important; width:auto !important;"' +
        ' src="' +
        iconDir +
        '" />'
      );
    }
  };
  Handlebars.registerHelper("iconify", utils.iconify);

  utils.iconifySpin = function(iconName, height, device, options) {
    if (typeof iconName !== "string" || iconName.trim().length <= 0) {
      return;
    }
    height = typeof height === "number" && height > 0 ? height : "auto";

    if (!device) {
      device = new utils.Device();
    }

    var color = options.hash.color || "wp-neutral-2",
      className = options.hash.className || "",
      fallback = options.hash.fallback || iconName,
      elementClass = "";

    // generate elements
    if (!device.isOperaMini()) {
      elementClass =
        "fa " + iconName + " fa-spin fa-" + color + " " + className;
      return (
        '<span class="' +
        elementClass +
        '" aria-hidden="true"' +
        ' style="font-size:' +
        height +
        'px;"></span>'
      );
    } else {
      elementClass = "fa " + iconName + " fallback " + className;
      return (
        '<img class="' +
        elementClass +
        '"' +
        ' style="height:' +
        height +
        'px !important; width:auto !important;"' +
        ' src="/img/icons/spin/' +
        fallback +
        '.gif" />'
      );
    }
  };
  Handlebars.registerHelper("iconifySpin", utils.iconifySpin);

  utils.validationElements = function(size) {
    var output,
      size = isNaN(size) ? 24 : size;
    output = utils.iconify(
      "fa-check",
      size,
      "wp-success",
      "form-control-feedback validation on-valid"
    );
    output += utils.iconify(
      "fa-warning",
      size,
      "wp-error-1",
      "form-control-feedback validation on-invalid"
    );
    return output;
  };
  Handlebars.registerHelper("validationElements", utils.validationElements);

  Handlebars.registerHelper("querystring", function() {
    return window.location.search;
  });

  Handlebars.registerHelper("linkify", function(text, options) {
    return utils.linkify(text, options);
  });

  utils.simpleShorten = function(text, charLimit, linkify, options) {
    var processed;

    options = options || {};

    if (!text) {
      return "";
    }

    processed = text.replace(/(<([^>]+)>)/gi, "");
    // WEB-6928: Remove empty lines within the text
    processed = processed.replace(/^\s*\n/gm, "");

    if (processed.length > charLimit) {
      processed =
        processed.substr(0, charLimit - 1).replace(/[\.,\- \/!&?]+$/, "") +
        "...";
    }
    return linkify === true ? utils.linkify(processed, options) : processed;
  };
  Handlebars.registerHelper("simpleShorten", utils.simpleShorten);

  Handlebars.registerHelper("shorten", function(text, lower, upper, linkify) {
    var result = {
        short: $('<div class="short-length">'),
        full: null
      },
      //Regex looks for the last occurent of '.', '!', or '?' in which there are no more occurences
      //of those punctuation marks.
      validPunctuation = /(\.|<\/a>|!|\?)(?=[^?!.>]*$)([\s\S]*)$/,
      bumper = 0,
      i = 0,
      trueUpper = upper,
      fullText,
      reset = true,
      shortText,
      linkifyFn = function(m) {
        //Get length of anchor + 4 for the closing
        var length = m.match(/(<a href[^>]*>)/i)[0].length + 4;
        //If this is a new round, reset the true Upper
        if (reset) {
          trueUpper = upper;
          reset = false;
        }
        //Add length to true upper
        trueUpper += length;
        //Modify the true character count
        i -= length;
      };

    linkify = linkify || false;

    if (typeof linkify === "boolean" && linkify) {
      fullText = text = utils.linkify(text, { sanitization: "none" });

      while (i < upper && trueUpper <= text.length) {
        //reset the character count to the augmented number
        i = trueUpper;
        //check for new href tags
        _.each(
          text.substr(0, trueUpper).match(/(<a href[^<]*<\/a>)/gi),
          linkifyFn
        );
        reset = true;
      }

      bumper = trueUpper - upper;
      upper += bumper;
      lower += bumper;
    }

    if (text && text.length > 0) {
      if (text.length < lower) {
        result.short.html("<pre>" + text + "</pre>");
      } else {
        result.full = $('<div class="full-length hide">');
        result.full.html("<pre>" + text + "</pre>");

        text = text.substr(0, upper);

        if (typeof linkify === "boolean" && linkify) {
          //Guards against unfinished links
          if (text.lastIndexOf("<a href") > text.lastIndexOf("</a>")) {
            text += fullText.substr(
              upper,
              fullText.substr(upper, fullText.length).indexOf("</a>") + 5
            );
          } else if (text.lastIndexOf("<") > text.length - 6) {
            text = text.substr(0, text.lastIndexOf("<"));
          }
        }

        shortText = text.replace(validPunctuation, "$1");

        if (shortText.length > lower) {
          result.short.html("<pre>" + text + "</pre>");
        } else {
          //Regex is same as above but for spaces
          shortText = text.replace(/( )(?=[^ ]*$)(.*)$/, "$1");
        }

        result.short.html("<pre>" + shortText + "</pre>");
      }
    }

    return $("<div>")
      .append(result.short)
      .append(result.full)
      .html();
  });

  utils.replace = function(str, find, replaceWith) {
    // Ensure we're working with a string before calling native replace()
    str = (str + "").toString();
    if (find && replaceWith) {
      return str.replace(find, replaceWith);
    } else {
      return str;
    }
  };
  Handlebars.registerHelper("replace", utils.replace);

  utils.formatDate = function(date, format) {
    if (!date) {
      return "-";
    }

    if (typeof format !== "string") {
      format = "YYYY-MM-DD";
    }
    return moment(date).format(format);
  };
  Handlebars.registerHelper("formatDate", utils.formatDate);

  utils.fromNow = function(time, options) {
    var result = moment(time),
      format = "MMM DD, YYYY hh:mmA";

    if (
      moment().diff(time, "hours") < 24 ||
      (options.hash.fuzzyTime && moment().diff(time, "days") < 7)
    ) {
      var dropSuffix = options.hash.dropSuffix || false;
      return result.fromNow(dropSuffix);
    } else if (options.hash.fuzzyTime) {
      //if its fuzzyTime, drop the time
      if (moment().isSame(time, "year")) {
        //if the year is same as the current year, drop the year.
        format = "MMM DD";
      } else {
        format = "MMM DD, YYYY";
      }
    }
    // i.e. January 1, 2016
    else if (options.hash.fullDate) {
      format = "MMMM D, YYYY";
    }

    return result.format(format);
  };
  Handlebars.registerHelper("fromNow", utils.fromNow);

  utils.calendar = function(time, options) {
    var displayTime = moment(time);
    if (moment().diff(displayTime, "days") > 6) {
      return displayTime.format("llll");
    } else {
      return displayTime.calendar();
    }
  };

  Handlebars.registerHelper("calendar", utils.calendar);

  utils.greaterThan = function(testNumber, limitNumber, options) {
    if (testNumber > limitNumber) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("greaterThan", utils.greaterThan);

  utils.lastNumberGreaterThan = function(testNumber, limitNumber, options) {
    if (testNumber % 10 >= limitNumber) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper(
    "lastNumberGreaterThan",
    utils.lastNumberGreaterThan
  );

  utils.reportCommentLink = function(username, commentBody) {
    return (
      "/help/report?author=" +
      encodeURIComponent(username) +
      "&comment=" +
      encodeURIComponent(commentBody)
    );
  };
  Handlebars.registerHelper("reportCommentLink", utils.reportCommentLink);

  Handlebars.registerHelper("connectCover", function(
    cover,
    title,
    width,
    klass,
    options
  ) {
    return new Handlebars.SafeString(
      '<img src="' +
        utils.resizeCover(cover, width) +
        '" alt="' +
        utils.sanitizeHTML(title) +
        '" height="' +
        width +
        '" class="' +
        klass +
        '" />'
    );
  });

  Handlebars.registerHelper("connectImage", function(cover, title, options) {
    // klass b/c ie8
    return new Handlebars.SafeString(
      '<img src="/img/' +
        cover +
        '" alt="' +
        title +
        '" class="' +
        options.hash.klass +
        '" id="' +
        options.hash.id +
        '" height="' +
        options.hash.height +
        '" width="' +
        options.hash.width +
        '" />'
    );
  });

  Handlebars.registerHelper("connectAvatar", function(
    avatarUrl,
    username,
    width,
    options
  ) {
    var url = utils.resizeAvatar(avatarUrl, width);
    return new Handlebars.SafeString(
      '<img src="' + url + '" width="' + width + '" height="' + width + '" />'
    );
  });

  /* Helper method to determine whether our story paragraph actually has text
   * pageText is HTML
   * */

  utils.hasContent = function(pageText, options) {
    if (pageText.length > 0) {
      if (
        $(pageText)
          .text()
          .trim().length > 0
      ) {
        return options.fn(this);
      }
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("hasContent", utils.hasContent);

  utils.conditionalClass = function(
    testSubject,
    positiveClassName,
    negativeClassName
  ) {
    if (testSubject) {
      return positiveClassName;
    } else if (negativeClassName) {
      return negativeClassName;
    } else {
      return "";
    }
  };
  Handlebars.registerHelper("conditionalClass", utils.conditionalClass);

  utils.supportsPlaceholder = function(device, options) {
    if (!utils.isOperaMini) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  };
  Handlebars.registerHelper("supportsPlaceholder", utils.supportsPlaceholder);

  Handlebars.registerHelper("select", function(value, label, selected) {
    if (selected && selected === value) {
      return (
        '<option value="' +
        value +
        '"' +
        'selected="selected">' +
        (label || value) +
        "</option>"
      );
    } else {
      return '<option value="' + value + '">' + (label || value) + "</option>";
    }
    return new Handlebars.SafeString(result);
  });

  utils.isCookieSet = function(cookieName, options) {
    return utils.getCookie(cookieName)
      ? options.fn(this)
      : options.inverse(this);
  };

  Handlebars.registerHelper("formatStoryUrl", function(value, options) {
    return utils.formatStoryUrl(value);
  });

  Handlebars.registerHelper("getCopyright", function(value, options) {
    return utils.getCopyright(value);
  });

  Handlebars.registerHelper("getCopyrightIcon", function(value, options) {
    return utils.getCopyrightIcon(value);
  });

  Handlebars.registerHelper("isCookieSet", utils.isCookieSet);

  Handlebars.registerHelper("urlEncode", function(text, options) {
    return utils.urlEncode(text);
  });

  Handlebars.registerHelper("urlEncodeWithSpace", function(text, options) {
    return utils.urlEncodeWithSpace(text);
  });

  Handlebars.registerHelper("ngettext", function(
    singular,
    plural,
    count,
    options
  ) {
    return utils.ngettext(singular, plural, count);
  });

  Handlebars.registerHelper("npgettext", function(
    context,
    singular,
    plural,
    count,
    options
  ) {
    return utils.npgettext(context, singular, plural, count);
  });

  utils.isExperimentVariation = function() {
    var args = Array.prototype.slice.call(arguments), // b/c not actually an array
      options = args[args.length - 1], // atleast 1 argument should exist
      variations = args.slice(1, -1), // exclude first and last item
      expName = args[0],
      curExperiment,
      expVariation;

    if (args.length >= 3) {
      // expName, variation, options
      curExperiment = window.app.experiments.get(expName);
      if (curExperiment) {
        // .selected() should be called regardless of whether we are in a variation or 'original'
        curExperiment.selected();
        expVariation =
          curExperiment.current === curExperiment.original
            ? "original"
            : curExperiment.current;
        if (variations.indexOf(expVariation) !== -1) {
          return options.fn(this);
        }
      }
    }
    return options.inverse(this);
  };
  Handlebars.registerHelper(
    "isExperimentVariation",
    utils.isExperimentVariation
  );

  utils.getExperimentVariation = function(expName, options) {
    var curExperiment = window.app.experiments.get(expName);
    if (curExperiment) {
      curExperiment.selected();
    }
    return curExperiment && curExperiment.current !== curExperiment.original
      ? curExperiment.current
      : "original";
  };
  Handlebars.registerHelper(
    "getExperimentVariation",
    utils.getExperimentVariation
  );

  utils.addProtocolToUrl = function(url) {
    return url
      ? url.search(/^http[s]?\:\/\//) == -1 && url.search(/^\/\//) == -1
        ? "http://" + url
        : url
      : "";
  };
  Handlebars.registerHelper("addProtocolToUrl", utils.addProtocolToUrl);

  // Replica of common.phps 'charsToWords()'
  // 6 characters per word is assumed except for some specific languages (ie: Chinese)
  utils.charsToWords = function(characterCount) {
    var languageId = parseInt(app.get("language"), 10);

    if (
      languageId === 8 ||
      languageId === 9 ||
      languageId === 10 ||
      languageId === 12
    ) {
      return characterCount;
    } else {
      return Math.ceil(characterCount / 6);
    }
  };
  Handlebars.registerHelper("charsToWords", utils.charsToWords);

  utils.wordCount = function(text) {
    var languageId = parseInt(app.get("language"), 10);
    text = text.trim().replace(/(<([^>]+)>)/gi, "");

    // Chinese (Traditional & Simplified), Japanese, Korean
    const characterBasedLanguages = [8, 9, 10, 12];

    if (characterBasedLanguages.includes(languageId)) {
      // Return length of string
      return text.length;
    } else {
      // Return number of string groups separated by spaces in string
      return text.length > 0
        ? _.filter(text.split(/\s+/), function(val) {
            return val.match(/\S+\s*/);
          }).length
        : 0;
    }
  };
  Handlebars.registerHelper("wordCount", utils.wordCount);

  utils.calcPercentRead = function(parts, position) {
    var numParts = parts.length,
      index = _.findIndex(parts, {
        id: parseInt(position.partId)
      }),
      percent =
        index / numParts + (1 / numParts) * parseFloat(position.position);

    percent = percent < 0 ? 0 : percent;
    return Math.round(percent * 10000) / 100;
  };
  Handlebars.registerHelper("calcPercentRead", utils.calcPercentRead);

  utils.rtlLanguage = function(languageId) {
    switch (languageId) {
      case 16: // Arabic
      case 17: // Hebrew
      case 31: // Persian
      case 48: // Urdu
        return true;
      default:
        return false;
    }
  };
  Handlebars.registerHelper("rtlLanguage", utils.rtlLanguage);

  utils.preFormat = function(text, noNewline) {
    if (noNewline === true) {
      return text.replace(/\n/g, "");
    }
    return text.replace(/\n/g, "<br />");
  };
  Handlebars.registerHelper("preFormat", utils.preFormat);

  utils.socialSharingAttributes = function(options) {
    var html = 'href="' + this.href + '" ';
    html += 'data-share-channel="' + this.name + '" ';

    // Hack such that React SocialSharing components (i.e., share_readinglist) doesn't use this
    // Migrating away from using this socialSharingAttributes helper to altogether in one SocialSharing.js file
    if (
      this.content !== "share_readinglist" &&
      !Handlebars.Utils.isEmpty(this.urlTemplate)
    ) {
      html += 'data-share-url="' + utils.urlEncode(this.urlTemplate) + '" ';
      html += 'data-url="' + utils.urlEncode(this.url) + '" ';
      html += 'data-share-text="' + utils.urlEncode(this.text) + '" ';
      html += 'data-share-content="' + utils.urlEncode(this.content) + '" ';
    }
    return new Handlebars.SafeString(html);
  };
  Handlebars.registerHelper(
    "socialSharingAttributes",
    utils.socialSharingAttributes
  );

  utils.styleHashtag = function(tag, options) {
    var styledTag = "#" + tag;
    if (styledTag == "#lgbt") {
      var colours = new Array(
        "rgb(228,3,3)",
        "rgb(255,140,0)",
        "rgb(0,128,38)",
        "rgb(0,77,255)",
        "rgb(117,7,135)"
      );
      var styleString = "";
      for (var i = 0; i < colours.length; ++i) {
        styleString +=
          '<span style="color: ' + colours[i] + '">' + styledTag[i] + "</span>";
      }
      styledTag = styleString;
    }

    return new Handlebars.SafeString(styledTag);
  };
  Handlebars.registerHelper("styleHashtag", utils.styleHashtag);

  // Helper that inserts ad unit
  Handlebars.registerHelper("insertOutstreamAd", getOutstreamAdvertisement);

  utils.publishedPartsInStory = function(group) {
    // For a group object, return the number of visible parts in that story
    var parts = group.parts || [];

    return parts.filter(function(part) {
      return !part.draft;
    });
  };

  utils.bonusChapterTypeName = function(type) {
    switch (type) {
      case 0:
        return utils.trans("Exclusive Chapter");
      case 1:
        return utils.trans("Story Branch");
      case 2:
        return utils.trans("Writer Reveal");
      default:
        return "";
    }
  };
  Handlebars.registerHelper("bonusChapterTypeName", utils.bonusChapterTypeName);

  Handlebars.registerHelper("renderTrinityAudioPlayer", function(
    partId,
    group
  ) {
    const voiceId = "Joanna";
    const isSubscribed = wattpad.utils.getCurrentUserAttr("isPremium") !== "";

    function renderPlayer() {
      // if we already have that tag in DOM, no need to re-add it. Just re-create it
      if (
        document.querySelector(
          `.trinity-player-tag[data-player-id='${partId}']`
        )
      ) {
        window.TRINITY_PLAYER.api.createPlayer(partId);
        return;
      }

      // otherwise - add tag
      const config = JSON.stringify({
        url: document.URL.replace(/^(\D*)(\d*)(.*)$/g, "$1amp/$2"),
        dataType: "html"
      });

      const scriptTag = document.createElement("script");
      scriptTag.className = "trinity-player-tag";
      scriptTag.dataset.playerId = partId;
      let scriptSrc = new URL(
        "https://trinitymedia.ai/player/trinity/2900001921/"
      );
      scriptSrc.searchParams.set("voiceId", voiceId);
      scriptSrc.searchParams.set("poweredby", "0");
      scriptSrc.searchParams.set("readContentType", "URL");
      scriptSrc.searchParams.set("readContentConfig", config);

      if (isSubscribed) {
        scriptSrc.searchParams.set("subscriber", "1");
      }
      scriptTag.src = scriptSrc.href;
      document.head.appendChild(scriptTag);
    }

    function registerPlayerEventTracking() {
      const storyId = group.id;
      const langId = group.language.id;
      const page = "reading";
      const countryCode = window.wattpad.userCountryCode;

      const eventDetailsCommonFields = {
        page,
        storyid: storyId,
        partid: partId,
        audio_type: "tts",
        is_subscribed: isSubscribed,
        country_code: countryCode,
        lang_id: langId,
        voice_type: voiceId
      };

      window.te.push(
        "event",
        "audio",
        null,
        null,
        "load",
        eventDetailsCommonFields
      );

      const pageLoadedTime = Date.now();
      window.addEventListener("message", event => {
        if (event.data?.type !== "TRINITY_TTS") {
          return;
        }
        const loadTime = Date.now() - pageLoadedTime;
        let eventSubSection = null;
        let eventAction = null;
        let eventDetails = null;

        if (event.data.value.action === "playerReady") {
          eventAction = "view";
          eventDetails = {
            ...eventDetailsCommonFields,
            load_time: loadTime
          };
        } else if (event.data.value.action === "playClicked") {
          eventAction = "play";
          eventDetails = {
            ...eventDetailsCommonFields,
            percent_complete: 0
          };
        } else if (event.data.value.action === "pauseClicked") {
          eventAction = "pause";
          eventDetails = eventDetailsCommonFields;
        } else if (event.data.value.action === "resumed") {
          eventAction = "resume";
          eventDetails = eventDetailsCommonFields;
        } else if (event.data.value.action === "onFirstQuartile") {
          eventAction = "play";
          eventDetails = {
            ...eventDetailsCommonFields,
            percent_complete: 25
          };
        } else if (event.data.value.action === "onMidPoint") {
          eventAction = "play";
          eventDetails = {
            ...eventDetailsCommonFields,
            percent_complete: 50
          };
        } else if (event.data.value.action === "onThirdQuartile") {
          eventAction = "play";
          eventDetails = {
            ...eventDetailsCommonFields,
            percent_complete: 75
          };
        } else if (event.data.value.action === "onComplete") {
          eventAction = "finish";
          eventDetails = eventDetailsCommonFields;
        } else if (event.data.value.action === "onAdStarted") {
          eventSubSection = "ad";
          eventAction = "begin";
          eventDetails = eventDetailsCommonFields;
        } else if (event.data.value.action === "onAdComplete") {
          eventSubSection = "ad";
          eventAction = "end";
          eventDetails = eventDetailsCommonFields;
        }

        if (eventAction && eventDetails) {
          window.te.push(
            "event",
            "audio",
            eventSubSection,
            null,
            eventAction,
            eventDetails
          );
        }
      });
    }

    function removePlayer() {
      const existingPlayerIds = Object.keys(window.TRINITY_PLAYER.players);
      for (const id of existingPlayerIds) {
        window.TRINITY_PLAYER.api.removePlayer(id);
      }
    }
    const ttsTag = "texttospeech";

    if (group.language.id === 1 && group.tags.includes(ttsTag)) {
      if (typeof window.playerRendered === "undefined") {
        window.playerRendered = true;
        renderPlayer();
        registerPlayerEventTracking();
      } else {
        if (!window.location.href.includes("/page/") && window.TRINITY_PLAYER) {
          removePlayer();
          renderPlayer();
        }
      }
    }

    return '<div class="trinityAudioPlaceholder"></div>';
  });

  Handlebars.registerHelper("parseEmbeddedMedia", function(text, imageWidth) {
    var $partText = $("<div>" + text + "</div>");
    imageWidth = imageWidth || 480;

    $partText
      .find("[data-video-source=youtube]")
      .each(function(index, videoParent) {
        var $videoParent = $(videoParent);
        var $newHtml = $(
          videoYoutube({
            videoId: $videoParent.data("videoId")
          })
        ).html();
        $videoParent.html($newHtml);
      });

    $partText
      .find("[data-video-source=vimeo]")
      .each(function(index, videoParent) {
        var $videoParent = $(videoParent);
        var $iframe = $(
          '<iframe width="764" height="430" src="//player.vimeo.com/video/' +
            $videoParent.data("videoId") +
            '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
        );
        $videoParent.html($iframe);
      });

    $partText
      .find("[data-video-source=vine]")
      .each(function(index, videoParent) {
        var $videoParent = $(videoParent);
        var $iframe = $(
          '<iframe width="764" height="430" src="//vine.co/v/' +
            $videoParent.data("videoId") +
            "/embed/simple" +
            '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>' +
            '<script src="https://platform.vine.co/static/scripts/embed.js"></script>'
        );
        $videoParent.html($iframe);
      });

    $partText
      .find("[data-video-source=wattpad]")
      .each(function(index, videoParent) {
        var $videoParent = $(videoParent);
        var $newHtml = $(
          inlineVideo({
            origWidth: $videoParent.data("original-width"),
            origHeight: $videoParent.data("original-height"),
            previewImageSrc: $videoParent.data("preview-image"),
            videoId: $videoParent.data("video-id"),
            videoSrc:
              "//v.wattpad.com/" + $videoParent.data("video-id") + "/hd.mp4"
          })
        ).html();

        $videoParent.html($newHtml);
      });

    $partText
      .find("[data-image-layout=one-horizontal]")
      .each(function(index, parentElement) {
        var $parentElement = $(parentElement);

        // fetch the last non-empty sentence of the previous paragraph and the first non-empty sentence of the next paragraph for alt tag usage
        var prevText = _.last(
          _.filter(
            $parentElement
              .prev("p")
              .text()
              .split("."),
            function(x) {
              return x !== "";
            }
          )
        ) || { length: 0 };
        var nextText = _.first(
          _.filter(
            $parentElement
              .next("p")
              .text()
              .split("."),
            function(x) {
              return x !== "";
            }
          )
        ) || { length: 0 };

        if (
          $parentElement.children("img") &&
          $parentElement.children("img").length === 1
        ) {
          var imgEl = $parentElement.children("img"),
            device = app.get("device"),
            pageWidth = (device.isDesktop() && 650) || 210;

          // image width is very close to the column width
          if (imgEl.data("original-width") > pageWidth) {
            var aspectRatio =
              (imgEl.data("original-height") / imgEl.data("original-width")) *
              100;
            $parentElement
              .addClass("fixed-ratio")
              .css({ "padding-bottom": aspectRatio + "%" });
          } else {
            // image width is narrower than the column by a non-trivial amount, let's not stretch it
            imgEl[0].setAttribute("width", imgEl.data("original-width"));
            imgEl[0].setAttribute("height", imgEl.data("original-height"));
          }

          // Enable browser-based lazy-loading of inline images.
          // If the browser does not support this attribute it will simply be ignored.
          imgEl.attr("loading", "lazy");

          imgEl.attr(
            "src",
            imgEl.attr("src") + "?s=fit&w=" + imageWidth + "&h=" + imageWidth
          );

          // if either the previous or next paragraph contain text, use the longest sentence as the alt tag
          if (prevText.length || nextText.length) {
            imgEl.attr(
              "alt",
              _.trunc(
                prevText.length > nextText.length ? prevText : nextText,
                250
              )
            );
          }

          var images = [
            {
              image: imgEl[0],
              src:
                imgEl[0].getAttribute("src") +
                "?s=fit&w=" +
                imageWidth +
                "&h=" +
                imageWidth
            }
          ];

          var $newHTML = $(
            oneHorizontalActive({
              id: $parentElement.data("p-id"),
              images: images,
              testGroups: wattpad.testGroups
            })
          );

          $parentElement.replaceWith($newHTML);
        }
      });

    return $partText.html();
  });

  utils.sanitizeHTML = function(str) {
    return str && typeof str === "string"
      ? str
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
      : str;
  };
  Handlebars.registerHelper("sanitize", utils.sanitizeHTML);

  utils.categoryNameToUrl = function(categoryName) {
    return `/stories/${categoryName}`;
  };
  Handlebars.registerHelper("categoryNameToUrl", utils.categoryNameToUrl);

  utils.sanitizeHTMLExceptQuotes = function(str) {
    return str && typeof str === "string"
      ? str.replace(/</g, "&lt;").replace(/>/g, "&gt;")
      : str;
  };
  Handlebars.registerHelper(
    "sanitizeExceptQuotes",
    utils.sanitizeHTMLExceptQuotes
  );

  utils.unsanitizeHTML = function(str) {
    return str && typeof str === "string"
      ? str
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/on[^\s]*=\S*/g, "")
      : str;
  };
  Handlebars.registerHelper("unsanitize", utils.unsanitizeHTML);

  Handlebars.registerHelper("sprintf", function() {
    return window.sprintf.apply(null, arguments);
  });

  Handlebars.registerHelper("reactComponent", function(
    componentName,
    componentId,
    options
  ) {
    window.activeReactComponents = window.activeReactComponents || [];

    // This function relies on the pre-update state of activeReactComponents
    // It must be called after activeReactComponents is set up, but before it is updated
    setupObserver();

    var model = (options && options?.hash?.model) || this; // Allows passing a 'model' property via Handlebars, otherwise defaults to the current Handlebars context
    var wrapperView;

    if (
      !(model instanceof Monaco.Model) &&
      !(model instanceof Monaco.Collection)
    ) {
      if (_.isArray(model)) {
        model = new Monaco.Collection(model);
      } else {
        model = new Monaco.Model(model);
      }
    }

    const containerId = generateContainerId(
      componentName,
      componentId,
      utils.urlEncode(window.location.pathname)
    );

    const existingInstanceArray = window.activeReactComponents.filter(
      ({ id }) => id === containerId.toLowerCase()
    );
    if (existingInstanceArray.length > 0) {
      const existingInstance = existingInstanceArray[0];
      wrapperView = existingInstance.view;
      existingInstance.view.model.clear({ silent: true });
      existingInstance.view.model.set(model.toJSON());
    } else {
      wrapperView = new app.views.ReactComponentWrapper(
        componentName,
        componentId,
        { model: model },
        options
      );
      wrapperView.render();
      window.activeReactComponents.push({
        id: wrapperView.containerId,
        view: wrapperView
      });
    }

    return new Handlebars.SafeString(wrapperView.container[0].outerHTML);
  });

  var setupObserver = function() {
    // Instantiate the observer singleton
    // Start observing DOM mutations, if we weren't observing before
    // Note: this must be called before activeReactComponents is mutated
    if (!window.observer) {
      window.observer = new MutationObserver(
        reactComponentWrapperObserverCallback
      );
    }

    if (window.activeReactComponents.length === 0) {
      var observerOptions = {
        subtree: true,
        childList: true
      };
      // we have to observe document.body rather than the direct parent of the wrapper since page navigation will happen
      // at the root #app-container node and will not cause the observer to see the change.
      window.observer.observe(document.body, observerOptions);
    }
  };

  var reactComponentWrapperObserverCallback = function(mutationList) {
    _.forEach(mutationList, removeReactComponents);

    // If there are no remaining active components, disconnect the observer
    // Note: this typically happens automatically, but in the case of the mutationobserver polyfill it will not
    // therefore we are doing it explicitly
    if (window.activeReactComponents.length <= 0) {
      window.observer.disconnect();
    }
  };

  var removeReactComponents = function(mutationDetails, index, mutationList) {
    if (
      mutationDetails.type === "childList" &&
      mutationDetails.removedNodes.length >= 1
    ) {
      var removedComponents = $(mutationDetails.removedNodes[0]).find(
        ".component-wrapper"
      );
      _.forEach(removedComponents, function(componentWrapper) {
        var containerId = componentWrapper.getAttribute("id");
        // Remove each removed component wrapper node from the list we're tracking in window.activeReactComponents
        // While removing the reference, call .remove() on the wrapper's Backbone view so that it's model listeners are destroyed
        _.remove(window.activeReactComponents, function(activeComponent) {
          if (activeComponent.id === containerId) {
            activeComponent.view.remove();
            return true;
          }
          return false;
        });
      });
    }
  };

  // Shows an ad every 2 pages after the first page
  // Does not show an ad on the last page, because the footer already has an ad
  Handlebars.registerHelper("shouldShowReadingAd", function(pageNumber, total) {
    return (pageNumber - 1) % 2 === 0 && pageNumber !== total;
  });

  Handlebars.registerHelper("ternary", function(
    conditional,
    resultIfTrue,
    resultIfFalse
  ) {
    if (_.isFunction(conditional)) {
      conditional = conditional.call(this);
    }
    return conditional ? resultIfTrue : resultIfFalse;
  });

  Handlebars.registerHelper("isTestGroup", function(testGroup) {
    return !!wattpad.testGroups[testGroup];
  });

  Handlebars.registerHelper("currentYear", function() {
    return wattpad.currentYear;
  });

  Handlebars.registerHelper("generateDropdownOptions", function(
    type,
    preservedVal
  ) {
    var pickerValues = "",
      saved = parseInt(preservedVal),
      min,
      max,
      top = moment(),
      past = moment().subtract(100, "years");

    switch (type) {
      case "day":
        min = 1;
        max = 31;
        pickerValues += '<option value="" disabled selected>Day</option>';
        break;
      case "month":
        min = 0;
        max = 11;
        pickerValues += '<option value="" disabled selected>Month</option>';
        break;
      case "year":
        min = past.year();
        max = top.year();
        pickerValues += '<option value="" disabled selected>Year</option>';
        break;
      default:
        break;
    }

    if (type === "year") {
      for (var i = max; i > min; i--) {
        pickerValues += Handlebars.helpers.select(i, i, saved);
      }
    } else {
      for (var i = min; i <= max; i++) {
        // Moment.js months are indexed from 0, aka June is month 5, so in order for the right
        // values and text to display for months, we need to add 1. Otherwise, for day, it remains the same.
        //   numFormatMax : In order for days and months smaller than 10 to have their value formatted with
        //      double digits (option value would be "09" instead of "9"), we add a 0 in front of the value itself
        //      Since months are zero-indexed, the max value for months is 9, since 9 is October
        //   curr : the current value of the option inside the picker
        //   text : the accompanying text corresponding to curr
        //
        //  We make these changes because platform expects birthdate to be formatted as "MM-DD-YYYY"
        //  so both day and month need to be 2 digits regardless of the value ("09" instead of "9")
        var numFormatMax = type === "month" ? 9 : 10,
          curr = type === "month" ? i + 1 : i,
          text =
            type === "month"
              ? moment()
                  .month(i)
                  .format("MMMM")
              : i;

        if (i < numFormatMax) {
          curr = "0" + curr;
        } else {
          curr = "" + curr;
        }

        pickerValues += Handlebars.helpers.select(curr, text, preservedVal);
      }
    }

    return pickerValues;
  });
})(window, Handlebars, _, window.wattpad);
